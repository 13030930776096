// reference https://locize.com/blog/next-app-dir-i18n/

import da from './locales/da/translation';
import en from './locales/en/translation';
export const fallbackLng = 'da';
export const languages = [fallbackLng, 'en'] as const;
export const defaultNS = 'translation';
export const cookieName = 'i18next';
export const translations = {
  da,
  en,
};

export type Languages = (typeof languages)[number];

export type ConditionAssessmentReportTranslations = keyof typeof translations.da.conditionAssessmentReport;

export function getOptions(lng = fallbackLng) {
  return {
    // debug: true,
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns: defaultNS,
  };
}
